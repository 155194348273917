import axios from "axios";
import * as state from "./dronos-state";
import { API_URL } from "./constants";

const authToken = state.getDronosToken()?.value;
const workspaceId=state.getUserInfo()?.member?.[0]?.workspace?._id;

function checkErrorCode(errorCode) {
    const unauthorizad = errorCode === 401
    if (unauthorizad) {
        state.destroyDronosToken()
        window.location.href = '/'
    }
}

const axiosIns = axios.create({
	baseURL: API_URL,
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": `${window.location.origin}/`,
		"Access-Control-Allow-Headers":
			"Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization",
		Authorization: authToken ? `Bearer ${authToken}` : '',
		workspaceId:workspaceId ? workspaceId :'64be821c04c3486120998051'
	},
	withCredentials: true,
});
axiosIns.interceptors.request.use(
	(config) => config,
	(error) => Promise.reject(error)
);
axiosIns.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		checkErrorCode(error.response.status)
		return Promise.reject(error);
	}
);
export default axiosIns;
